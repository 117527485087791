import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex justify-between mb-4 hidden sm:block" }
const _hoisted_3 = { class: "text-3xl font-bold text-gray-800 dark:text-gray-100" }
const _hoisted_4 = { class: "text-base" }
const _hoisted_5 = { class: "text-gray-400 font-mediun mt-auto" }
const _hoisted_6 = { ref: "items" }
const _hoisted_7 = { class: "text-sm text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item = _resolveComponent("item")!

  return (_ctx.manga && _ctx.chapter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.manga.name) + " ", 1),
            _createElementVNode("span", _hoisted_4, "/ " + _toDisplayString(_ctx.chapter.name), 1)
          ]),
          _createElementVNode("p", _hoisted_5, "chapters: " + _toDisplayString(_ctx.manga.chapters.length), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page, index) => {
            return (_openBlock(), _createBlock(_component_item, {
              key: page.id,
              id: `page-${index}`,
              name: `Page: ${page.number}`,
              "image-id": page.id,
              "is-read": page.isRead,
              to: {
          name: 'page',
          params: {
            mangaName: _ctx.manga.name,
            chapterNumber: `${_ctx.chapter.number}`,
            pageNumber: `${page.number}`,
          },
        }
            }, {
              legend: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, "Page: " + _toDisplayString(page.number) + " / " + _toDisplayString(_ctx.pages?.length), 1)
              ]),
              _: 2
            }, 1032, ["id", "name", "image-id", "is-read", "to"]))
          }), 128))
        ], 512)
      ]))
    : _createCommentVNode("", true)
}